define('jason-frontend/controllers/practicemgmt/treatment-visit-details', ['exports', 'ember', 'jason-frontend/api'], function (exports, Ember, API) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    breadCrumb: "Stationsaufenthalt",
    breadCrumbPath: "practicemgmt.treatment-visit-details",
    selectTab: 'journal',
    sidebarActive: true,
    customerTab: 'masterdata',
    patientTab: '',
    healthTab: 'health',
    actions: {
      save: function save(id) {
        var self = this;

        var data = {
          id: this.get('model').id,
          notice: $('#notice').val(),
          title: $('#title').val()
        };

        if (data.id > 0) {
          var deferred = $.ajax({
            url: "/api/treatmentStationVisits",
            method: "POST",
            data: JSON.stringify(data),
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            // self.set('currentVersion', data.treatmentPatient.version);
            // console.log('updated to: ' + data.treatmentPatient.version);
          }, function (error) {});
        } else {
          jason.notifiction.systemError('Fehlercode 117', 'Eingaben konnte nicht gespeichert werden');
        }

        return Ember['default'].RSVP.resolve(deferred);

        // let self = this;
        // setTimeout(function() {
        //   self.saveIntern(id);
        // }, 300);
      },
      autoSave: function autoSave() {
        if (this.get('inputDirty')) {
          this.send('save', this.get('model').id);
          this.set('inputDirty', false);
        }
      },
      setInputDirty: function setInputDirty() {
        this.set('inputDirty', true);
      },
      openEditDosing: function openEditDosing(item) {
        this.get('editPastActionsPanel').send('load', item);

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-past-actions-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openRevenue: function openRevenue(id) {
        this.get('customerRevenuePanel').send('load', id);
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-revenue-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openCustomerReductions: function openCustomerReductions(id) {
        var self = this;
        this.store.fetch('customer', id).then(function (data) {
          self.get('setCustomer').send('load', data);
        });
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-customer-reductions-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectBarcode: function selectBarcode(patientId) {
        this.get('patientSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-patient-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectSlopingBarcode: function selectSlopingBarcode(entryId, data) {

        var self = this;

        if (data) {
          $.ajax({
            url: "/api/treatmentOffers/" + self.get('model').get('offerId') + "/changeSlopingInfo/" + entryId,
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            var viewUrl = '/api/treatmentOffers/downloadSloping/' + entryId + '/_logo?mandantId=' + API['default'].getMandant() + '&downloadToken=' + API['default'].getDownloadToken();

            var myWindow = window.open(viewUrl, 'Drucken', 'width=600,height=600');

            var is_chrome = Boolean(window.chrome);
            if (is_chrome) {
              myWindow.onload = function () {
                myWindow.moveTo(0, 0);
                myWindow.resizeTo(640, 480);
                myWindow.print();
              };
            } else {
              myWindow.print();
              myWindow.close();
            }
          }, function (error) {});
        } else {
          this.openBarcodeSelection(entryId);
        }
      },
      selectLaboklinBarcode: function selectLaboklinBarcode(patientId) {
        this.get('laboklinSelectBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-laboklin-select-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      selectPrivateLabBarcodePanel: function selectPrivateLabBarcodePanel(patientId) {
        this.get('privateLabBarcodePanel').send('load', patientId);
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-private-lab-barcode-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      scan: function scan(directEhapo) {
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-quick-scan-product-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
        this.get('quickScanProductPanel').send('load', 'ehapo', directEhapo);
      },
      openCorrections: function openCorrections(id) {

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('inventoryCorrectionPanel').send('load', data.inventoryStock);
        }, function () {});

        setTimeout(function (e) {
          $.magnificPopup.open({
            removalDelay: 0,
            closeOnBgClick: false,
            items: {
              src: '#modal-inventory-correction-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 500);
      },
      openSlopingInfos: function openSlopingInfos(entry) {

        var self = this;

        var articleId = undefined;
        var article = undefined;
        try {
          articleId = entry.get('article').id;
          article = entry.get('article');
        } catch (e) {
          articleId = entry.article.id;
          article = entry.article;
        }

        $.ajax({
          url: "/api/products/" + articleId + "/slopingInfos",
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('slopingInfoPanel').send('load', data.productSlopingInfo, article, entry);
        });

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-sloping-info-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reloadTemplate: function reloadTemplate(instanceId, readOnly) {
        var self = this;
        $.ajax({
          url: "/api/templateInstances/" + instanceId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('templateEditPanel').send('load', data.templateInstance, "templateInstances", readOnly, self.get('model').get('patient').id);
        });
      },
      openTemplateEdit: function openTemplateEdit(tpId, instanceId, readOnly) {
        this.transitionTo('editor.edit', instanceId, 'templateinstance', tpId);
      },
      openCheckInventoryInput: function openCheckInventoryInput(nr, unitId) {
        var self = this;
        $.ajax({
          url: "/api/inventoryStocks/checkNumber/" + nr + "?desiredUnit=" + unitId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('selectInventoryPanel').send('selectStock', data.inventoryStock);
        }, function (error) {
          jason.notifiction.error('Fehler', 'Die eingegebene Nummer konnte keinem Warenbestand zugeordnet werden');
        });
      },
      openDocumentForwardPanel: function openDocumentForwardPanel(id) {

        var customer = this.get('model').get('patientOwner');
        if (customer) {
          this.get('forwardDocumentPanel').send('load', id, customer.email);
        }

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-document-forward-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openLabResult: function openLabResult(id) {

        var self = this;

        $.ajax({
          url: "/api/labResults/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('labResultPanel').send('load', data.labResultFrontend, self.get('model').get('patient'), self.get('model').get('patientOwner'));
        }, function (error) {});

        setTimeout(function () {
          $.magnificPopup.open({
            removalDelay: 0,
            closeOnBgClick: false,
            items: {
              src: '#modal-lab-result-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      openInventoryStockPanel: function openInventoryStockPanel(entry, model) {

        if (model) {
          var id = entry.get('article').id,
              productName = entry.get('article').get('name'),
              necessary = entry.get('unitQuantity') * entry.get('quantity'),
              entryId = entry.id,
              connectedMovements = entry.get('movements'),
              fullyBooked = entry.get('fullyBooked'),
              alreadyBooked = entry.get('alreadyBooked'),
              unitName = entry.get('unit').name,
              unitId = entry.get('unit').id;
        } else {
          var id = entry.article.id,
              productName = entry.article.name,
              necessary = entry.unitQuantity * entry.quantity,
              entryId = entry.id,
              connectedMovements = entry.movements,
              fullyBooked = entry.fullyBooked,
              alreadyBooked = entry.alreadyBooked,
              unitName = entry.unit.name,
              unitId = entry.unit.id;
        }

        var self = this;

        $.ajax({
          url: "/api/inventoryStocks/product/" + id + "?desiredUnit=" + unitId,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('selectInventoryPanel').send('load', data.inventoryStock, necessary, id, productName, self.get('model').id, self.get('model').get('offerId'), entryId, connectedMovements, fullyBooked, alreadyBooked, unitName, unitId);
        }, function () {});

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-select-inventory-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openEmailForwardPanel: function openEmailForwardPanel(id) {

        var customer = this.get('model').get('patientOwner');
        if (customer) {
          this.get('forwardEmailPanel').send('load', id, customer.email);
        } else {
          this.get('forwardEmailPanel').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 0,
          items: {
            src: '#modal-email-invoice-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openForwardResult: function openForwardResult(id) {

        var customer = this.get('model').get('patientOwner');
        if (customer) {
          this.get('sendLabResultEmail').send('load', id, customer.email);
        } else {
          this.get('sendLabResultEmail').send('load', id);
        }

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-email-labResult-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAddDirectNotification: function openAddDirectNotification(id) {
        var self = this;

        var self = this;
        this.set('users', this.store.find('user', { per_page: 100 }));

        this.store.find('mandant').then(function (data) {
          self.set('mandants', data);

          data.forEach(function (item) {
            if (item.id === API['default'].getMandant()) {
              self.set('mandant', item);
            }
          });
        });

        this.get('addDirectNotification').send('reset');

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-direct-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openAddAppDirectNotification: function openAddAppDirectNotification(id) {
        var self = this;

        $.magnificPopup.open({
          removalDelay: 0,
          items: {
            src: '#modal-add-direct-app-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      reloadLaboklin: function reloadLaboklin(type) {

        var self = this;
        $.ajax({
          url: "/api/patients/" + self.get('model').get('patient').id + "/laboklinForms?sortDir=" + self.get('treatmentSortDir') + "&type=" + type,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('laboklinHistoricLabs').send('load', data.purchaseOrderLaboklinForm);
        });
      },
      openHistoricLabs: function openHistoricLabs(id) {
        var self = this;

        $.ajax({
          url: "/api/patients/" + id + "/laboklinForms?sortDir=" + self.get('treatmentSortDir'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('laboklinHistoricLabs').send('load', data.purchaseOrderLaboklinForm);
        });

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-laboklin-historic-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      addNewLaboklin: function addNewLaboklin(product, id) {

        var self = this;
        this.get('laboklinTreatmentPanel').send('load', product, id, null, self.get('model').get('patient'), self.get('model').get('patientOwner'), self.store.find('patient-category'), null, null);
        Ember['default'].run.later(function () {
          $.magnificPopup.open({
            removalDelay: 0,
            items: {
              src: '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        }, 1000);
      },
      editLabWrapper: function editLabWrapper(formId) {
        this.editLab(this.get('model').get('treatmentPatientId'), formId);
      },
      editLab: function editLab(treatmentPatientId, laboklinFormId) {
        this.editLab(treatmentPatientId, laboklinFormId);
      },
      openLaboklinChoosePanel: function openLaboklinChoosePanel(entryId, laboklinFormId, product, privateTreatment, subType) {
        var self = this;
        if (laboklinFormId > 0) {
          $.ajax({
            url: "/api/treatmentPatients/" + self.get('model').get('treatmentPatientId') + "/laboklinForms/" + laboklinFormId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            if (privateTreatment) {
              self.get('privateTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, data, null, null, null, null, null);

              var profile = product.get('labProfile');
              if (profile) {

                $.ajax({
                  url: "/api/labProfiles/" + profile.id,
                  method: "GET",
                  contentType: "application/json",
                  dataType: "json",
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (labProfileData) {
                  self.get('privateTreatmentPanel').send('setLabProfile', labProfileData.labProfile);
                });
              } else if (product.get('vetconnectTest')) {
                self.get('privateTreatmentPanel').send('setVetconnectTest', product.get('vetconnectTest'));
              } else if (subType === 'zoetis') {
                self.get('privateTreatmentPanel').send('setZoetisTest', product.get('zoetisTest'));
              }
            } else {
              if (subType === 'aniveri') {
                self.get('aniveriTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, data, null, null, self.store.find('patient-category'), null, null);
              } else {
                self.get('laboklinTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, data, null, null, self.store.find('patient-category'), null, null);
              }
            }
          });

          $.magnificPopup.open({
            removalDelay: 0,
            closeOnBgClick: false,
            items: {
              src: privateTreatment ? '#modal-form-private-treatment-panel' : subType === 'aniveri' ? '#modal-form-aniveri-treatment-panel' : '#modal-form-laboklin-treatment-panel'
            },
            callbacks: {
              beforeOpen: function beforeOpen() {
                var Animation = "mfp-with-fade";
                this.st.mainClass = Animation;
              }
            },
            midClick: true
          });
        } else {
          $.ajax({
            url: "/api/treatmentPatients/" + self.get('model').get('treatmentPatientId') + "/laboklinForms",
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {

            var foundFormId = null;

            $(data.purchaseOrderLaboklinForm).each(function (index, value) {

              if (value.orderTypeId !== null && value.orderTypeId === product.get('labCategory') && value.status.id === 'created') {
                foundFormId = value.id;
              }
            });

            if (foundFormId) {
              $.ajax({
                url: "/api/treatmentPatients/1/linkLaboklinForm/" + entryId + "/" + foundFormId,
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                headers: {
                  'X-AUTH-TOKEN': API['default'].getToken()
                }
              }).then(function () {

                self.send('reloadModel');

                $.ajax({
                  url: "/api/treatmentPatients/" + self.get('model').get('treatmentPatientId') + "/laboklinForms/" + foundFormId,
                  method: "GET",
                  contentType: "application/json",
                  dataType: "json",
                  headers: {
                    'X-AUTH-TOKEN': API['default'].getToken()
                  }
                }).then(function (data) {
                  self.get('laboklinTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, data, null, null, self.store.find('patient-category'), null, null);
                });

                $.magnificPopup.open({
                  removalDelay: 0,
                  items: {
                    src: privateTreatment ? '#modal-form-private-treatment-panel' : '#modal-form-laboklin-treatment-panel'
                  },
                  callbacks: {
                    beforeOpen: function beforeOpen() {
                      var Animation = "mfp-with-fade";
                      this.st.mainClass = Animation;
                    }
                  },
                  midClick: true
                });
              }, function () {});
            } else {

              if (privateTreatment) {
                self.get('privateTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, null, self.get('model').get('patient'), self.get('model').get('patientOwner'), self.store.find('patient-category'), null, product, null);

                var profile = product.get('labProfile');

                if (profile) {

                  $.ajax({
                    url: "/api/labProfiles/" + profile.id,
                    method: "GET",
                    contentType: "application/json",
                    dataType: "json",
                    headers: {
                      'X-AUTH-TOKEN': API['default'].getToken()
                    }
                  }).then(function (labProfileData) {
                    self.get('privateTreatmentPanel').send('setLabProfile', labProfileData.labProfile);
                  });
                } else if (product.get('zoetisTest')) {
                  self.get('privateTreatmentPanel').send('setZoetisTest', product.get('zoetisTest'));
                } else if (product.get('vetconnectTest')) {

                  $.ajax({
                    url: "/api/treatmentPatients/" + self.get('model').get('treatmentPatientId') + "/formConnectionCheck/" + entryId,
                    method: "GET",
                    contentType: "application/json",
                    dataType: "json",
                    headers: {
                      'X-AUTH-TOKEN': API['default'].getToken()
                    }
                  }).then(function (connectionCheckData) {
                    self.get('privateTreatmentPanel').send('setVetconnectConnection', connectionCheckData.vetconnectConnectionStatus);
                  });

                  $.ajax({
                    url: "/api/vetconnect/devices",
                    method: "GET",
                    contentType: "application/json",
                    dataType: "json",
                    headers: {
                      'X-AUTH-TOKEN': API['default'].getToken()
                    }
                  }).then(function (devices) {
                    self.get('privateTreatmentPanel').send('setVetconnectTest', product.get('vetconnectTest'), devices.vetConnectDevice);
                  });
                }
              } else {
                self.get('laboklinTreatmentPanel').send('load', self.get('model').get('treatmentPatientId'), entryId, null, self.get('model').get('patient'), self.get('model').get('patientOwner'), self.store.find('patient-category'), null, product, null);
              }
              Ember['default'].run.later(function () {
                $.magnificPopup.open({
                  removalDelay: 0,
                  items: {
                    src: privateTreatment ? '#modal-form-private-treatment-panel' : '#modal-form-laboklin-treatment-panel'
                  },
                  callbacks: {
                    beforeOpen: function beforeOpen() {
                      var Animation = "mfp-with-fade";
                      this.st.mainClass = Animation;
                    }
                  },
                  midClick: true
                });
              }, 500);
            }
          });
        }
      },
      openXrayEntryPanel: function openXrayEntryPanel(entryId, xrayId, product) {
        var self = this;

        if (xrayId) {

          $.ajax({
            url: "/api/xrayLogbookEntries/" + xrayId,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('xrayLogbookEntryPanel').send('load', entryId, data.xrayLogbookEntry, product);
          });
        } else {
          self.get('xrayLogbookEntryPanel').send('loadNew', entryId, product);
        }

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-xray-logbook-entry-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openChart: function openChart(data, paramId, formId) {
        this.get('labResultChartPanel').send('load', data, paramId, formId);
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-lab-result-chart-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openDirectMessage: function openDirectMessage(id, type) {

        var self = this;
        if (type === 'finished') {
          $.ajax({
            url: "/api/mandants/emailText/" + id,
            method: "GET",
            contentType: "application/json",
            dataType: "json",
            headers: {
              'X-AUTH-TOKEN': API['default'].getToken()
            }
          }).then(function (data) {
            self.get('addDirectAppNotificationPanel').send('loadWithPreset', id, data.mandantEmailText.appReorderConfirm);
          });
        } else {
          this.get('addDirectAppNotificationPanel').send('load', id, false);
        }

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-add-direct-app-notification-panel'
          },
          callbacks: {
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openMedicalProblemsPanel: function openMedicalProblemsPanel(id) {
        var self = this;

        $.ajax({
          url: "/api/patients/" + id,
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.get('medicalProblemsPanel').send('load', data.patient);
        });

        if ($.magnificPopup.instance.isOpen) {
          $.magnificPopup.instance.close();
        }

        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-edit-medical-problems-panel'
          },
          callbacks: {
            close: function close() {
              self.get('medicalProblemsPanel').send('save');
            },
            open: function open() {
              $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      },
      openMediaPanel: function openMediaPanel(id) {
        var self = this;

        this.store.find('media', { patientId: id }).then(function (data) {

          self.get('mediaPanel').send('load', data);
        });
        $.magnificPopup.open({
          removalDelay: 0,
          closeOnBgClick: false,
          items: {
            src: '#modal-media-panel'
          },
          callbacks: {
            open: function open() {
              $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            beforeOpen: function beforeOpen() {
              var Animation = "mfp-with-fade";
              this.st.mainClass = Animation;
            }
          },
          midClick: true
        });
      }
    }
  });

});