define('jason-frontend/components/pdf-preview-panel', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions', 'jason-frontend/api'], function (exports, Ember, InboundActions, API) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    url: null,
    prefix: null,

    actions: {
      download: function download(id) {
        this.sendAction('download', id);
      },
      load: function load(document) {
        var id = document.get('internalId');
        this.set('id', id);
        var self = this;
        this.set('document', document);

        var hostAppendix = '';
        if (location.hostname === "localhost") {
          hostAppendix = 'http://localhost:8080';
        }

        $.ajax({
          url: "/api/users/rat",
          method: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            id: id
          }),
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {}, function (data) {
          var url = hostAppendix + '/api/media/stream/' + data.responseText;
          self.set('viewUrl', url);
          setTimeout(function () {
            self.get('pdfPreview').send('showUrl', self.get('viewUrl'));
          }, 3000);
        });
      }
    }
  });

});