define('jason-frontend/components/pdf-preview', ['exports', 'ember', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    showLoading: false,
    actions: {
      show: function show() {
        this.showSlip(null);
      },
      showUrl: function showUrl(url) {
        this.showSlip(url);
      }
    },
    showSlip: function showSlip(inputUrl) {

      var url = inputUrl ? inputUrl : $('div#pdf-preview > a').attr('href'),
          isInvoice = $('div#pdf-preview').data('invoice'),
          isPdf = $('div#pdf-preview').data('pdf'),
          isClosing = $('div#pdf-preview').data('closing');

      var width = "500px",
          height = "800px";

      if (isInvoice) {
        width = "800px";
        height = "1200px";
      }
      if (isPdf) {
        width = "1000px";
        height = "1200px";
      }

      var success = new PDFObject({
        url: url,
        width: width,
        height: height
      }).embed("pdf-preview");

      this.set('showLoading', false);
    },
    becomeFocused: (function () {
      var element = $('div#pdf-preview'),
          self = this,
          isInvoice = $('div#pdf-preview').data('invoice');

      if (element.data('initial-openend')) {
        setTimeout(function () {
          self.showSlip();
        }, isInvoice ? 3000 : 1000);
      }
    }).on('didInsertElement')
  });

});