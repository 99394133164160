define('jason-frontend/components/media-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    smallView: false,
    tagName: 'span',
    withLinks: false,
    showPath: true,
    actions: {
      preview: function preview(document) {
        var id = document.get('internalId'),
            mime = document.get('mime'),
            type = document.get('type'),
            internalType = document.get('internalType');

        console.log(internalType);

        if (mime === 'image') {
          this.sendAction('preview', document);
        } else if (mime === 'pdf' && !internalType) {
          this.sendAction('pdfPReview', document);
        } else {
          if (type === 'order') {
            this.sendAction('downloadOrder', id);
          } else if (type === 'invoice') {
            this.sendAction('downloadInvoice', id);
          } else if (type === 'deliverynote') {
            this.sendAction('downloadDeliveryNote', id);
          } else if (type === 'labLaboklin') {
            this.sendAction('downloadLaboklinRequest', id);
          } else if (type === 'labResultIntern') {
            this.sendAction('downloadLabResultIntern', id);
          } else if (type === 'labResultLaboklin') {
            this.sendAction('downloadLabResultLaboklin', id);
          } else if (type === 'vetconnect') {
            this.sendAction('downloadVetconnect', id);
          } else {
            this.sendAction('download', id);
          }
        }
      }
    }
  });

});