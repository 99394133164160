define('jason-frontend/components/product-select-panel', ['exports', 'ember', 'jason-frontend/api', 'ember-component-inbound-actions/inbound-actions'], function (exports, Ember, API, InboundActions) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(InboundActions['default'], {
    loading: false,
    selectedTextModule: null,
    timerId: {},
    actions: {
      confirm: function confirm() {
        this.set('loading', true);
        var self = this;

        Ember['default'].$.ajax({
          url: "/api/products?searchTerm=" + this.get('filterName'),
          method: "GET",
          contentType: "application/json",
          dataType: "json",
          headers: {
            'X-AUTH-TOKEN': API['default'].getToken()
          }
        }).then(function (data) {
          self.set('results', data.product);
        }, function (data) {});
        this.set('loading', false);
      },
      select: function select(item) {
        if (this.get('selectedTextModule') === 'productWarningNotice') {
          var value = item.individualWarningNotice;
          if (!value) {
            value = item.warningNotice;
            if (!value) {
              value = '';
            }
          }
          this.sendAction('selected', value);
        } else if (this.get('selectedTextModule') === 'product') {
          var value = item.nameSuggest;
          if (item.licenceNumber) {
            value += " (" + item.licenceNumber + ")";
          }
          this.sendAction('selected', value);
        } else if (this.get('selectedTextModule') === 'productLicenceNumber') {
          var value = item.licenceNumber;
          this.sendAction('selected', value);
        } else if (this.get('selectedTextModule') === 'productDosing') {
          var value = item.dosingNotice;
          if (!value) {
            value = '';
          }
          this.sendAction('selected', value);
        } else if (this.get('selectedTextModule') === 'substanceNotice') {
          var value = item.individualSubstanceNotice;
          console.log(item);
          if (!value) {
            value = item.ingredients;
            if (!value) {
              value = '';
            }
          }
          this.sendAction('selected', value);
        } else if (this.get('selectedTextModule') === 'productSubstanceNotice') {
          var value = item.individualSubstanceNotice;
          if (!value) {
            value = item.ingredients;
            if (!value) {
              value = '';
            }
          }
          this.sendAction('selected', value);
        }
        $.magnificPopup.close();
      },
      cancel: function cancel() {
        $.magnificPopup.close();
      },
      load: function load(context) {
        setTimeout(function () {
          $('#filterProductName').focus();
        }, 1000);
      }
    },
    watchSearch: (function () {
      var self = this;
      var type = 'defaultTimer';

      this.set('loading', true);
      if (this.timerId[type]) {
        window.clearTimeout(this.timerId[type]);
      }
      this.timerId[type] = window.setTimeout(function () {
        if (self.get('filterName') && self.get('filterName').length > 2) {
          self.send('confirm');
        } else {
          self.set('loading', false);
        }
      }, 500);
    }).observes('filterName')
  });

});